export default [
  {
    title: "Página Inicial",
    route: "/dashboard",
    permissao: "paginainicial",
    iconClass: "fas fa-chalkboard-teacher",
  },
  {
    title: "Gerenciamento",
    route: null,
    permissao: "c_Usuario",
    iconClass: "fas fa-cogs",
    subItems: [
      {
        title: "Permissões",
        route: "/permissoes",
        permissao: "c_Role",
      },

      {
        title: "Empresa",
        route: "/empresa",
        permissao: "c_Empresa",
      },
      {
        title: "Filial",
        route: "/filial",
        permissao: "c_Filial",
      },

      {
        title: "Perfil",
        route: "/perfil",
        permissao: "c_Perfil",
      },
      {
        title: "Setor",
        route: "/setor",
        permissao: "c_Setor",
      },
    ],
  },

  {
    title: "RH",
    route: null,
    permissao: "c_Usuario",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Usuarios",
        route: "/gerenFuncionarios",
        permissao: "c_Usuario",
      },
    ],
  },

  {
    title: "Gerência de Protocos",
    route: null,
    permissao: "ra_TipoProtocolo",
    iconClass: "far fa-comment-dots",
    subItems: [
      {
        title: "Tipo Protocolo",
        route: "/tipoProtocolo",
        permissao: "ra_TipoProtocolo",
      },
    ],
  },


  {
    title: 'Financeiro',
    route: null,
    permissao: "c_FinanContasReceber",
    iconClass: 'fas fa-dollar-sign',
    subItems: [

   
      {
        title: 'Servico',
        route: '/servicos',
        permissao: 'ra_Produto'
      },
      
      {
        title: 'Contrato',
        route: '/produto',
        permissao: 'ra_Produto'
      },
      {
        title: 'Contrato Serviços',
        route: '/contratoServico',
        permissao: 'ra_ContratoServicos'
      },
      
      {
        title: 'Tipo Contrato',
        route: '/tipoProduto',
        permissao: 'ra_TipoProduto' 
      },
  //     {
  //       title: 'Modos de pagamento',
  //       route: '/modoPagamento',
  //       permissao: 'ra_FinanModoPagamento'
  //     },

  //     {
  //       title: 'Formas de Pagamento',
  //       route: '/formaPagamento',
  //       permissao: 'ra_FinanFormaPagamento'

  //     },
  //     {
  //       title: 'Pedidos a receber',
  //     route: '/finanPedidoRecebimento',
  //     permissao:'ra_FinanContasReceber'
  // },

  
      // {
      //     title: 'Pedidos de Compra',
      //     route: '/pedidoCompra',
      //     permissao:'ra_FinanPedidoCompra'
      // },
      // {
      //     title: 'Pedidos de Recebimento',
      //     route: '/finanPedidoRecebimento',
      //     permissao:'ra_FinanPedidoRecebimento'
      // },
      // {
      //     title: 'Contas a Pagar',
      //     route: '/finanContasPagar',
      //     permissao:'ra_FinanContasPagar'
      // },
      // {
      //     title: 'Contas a Receber',
      //     route: '/finanContasReceber',
      //     permissao:'ra_FinanContasReceber'
      // },


    ]
  },

  
  






 

 
];

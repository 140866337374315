<template>
  <div
    class="subheader py-3 py-lg-8 subheader-transparent"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div v-if="lista_permissoes_filial['ra_Filial']" class="d-flex align-items-baseline mr-5">
        <h2>{{filial}} -  </h2>
        <!--begin::Page Title-->
        <h2 class="subheader-title text-dark font-weight-bold my-2 ml-1 mr-3">
           {{ title }}
        </h2>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
   
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <!-- <span
                class="text-muted"
                :key="`${i}-${breadcrumb.id}`"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span> -->
            </li>
          </template>
        </ul>
        <!--end::Breadcrumb-->
      </div>

      <!--begin::Toolbar-->
      
      <div class="d-flex align-items-center">
        <div v-for="(item, key) in subMenu" :key="key">
          <div v-if="title == item.title">
            <b-button-group v-for="(botoes, key) in item.botoes" :key="key">
              <router-link :to="botoes.route" v-slot="{ navigate }" 
              v-if="lista_permissoes_filial[botoes.permissao]">
                <b-button
                  @click="navigate"
                  class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2"
                  variant="default"
                  style="color: black"
                >
                  {{ botoes.title }}
                </b-button>
              </router-link>
            </b-button-group>
          </div>
        </div>
    

 
        <!--end::Button-->
        <!--begin::Dropdown-->
      
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import menus from "@/core/router/subMenu";
import FilialService from "@/core/services/filial.service";

export default {
  name: "KTSubheader",


  props: {
    breadcrumbs: Array,
    title: String
  },
  data() {
    return {
      subMenu: menus,
    };
  },
  created(){

  },
  computed: {
    filial(){
      let filial_id = this.$store.state.sistema.id_filial_selecionada;
      let filiais = FilialService.getFiliais()
      return filiais[filial_id].filial
    },
    ...mapGetters(["layoutConfig"]),

    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>
